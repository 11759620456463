.wallpaper,
.form {
    min-height: 95vh;
}

.wallpaper img {
    width: 65%;
}

.form {
    width: 100%;
    padding-left: 20%;
}

.buttonStack button {
    height: 52px;
    border-radius: 50px;
    box-shadow: var(--box-shadow-1);
}

.shadowButton {
    height: 52px;
    border-radius: 50px !important;
    box-shadow: var(--box-shadow-1);
}

.signInOTPButton {
    height: 52px;
    border-radius: 50px !important;
}


.divider {
    margin-top: 30px !important;
    border: 1px solid rgba(0, 0, 0, 0.29) !important;
}


@media only screen and (max-width: 1536px) {

    .wallpaper,
    .form {
        margin: 20px 0;
        min-height: unset;
    }

    .wallpaper img {
        padding-top: 20px;
        width: 70%;
    }

    .form {
        padding-left: 20%;
        padding-right: 20%;
    }
}

@media only screen and (max-width: 600px) {

    .wallpaper,
    .form {
        margin: 20px 0;
        min-height: unset;
    }

    .wallpaper img {
        padding-top: 20px;
        width: 90%;
    }

    .form {
        padding-left: 5%;
        padding-right: 5%;
    }
}