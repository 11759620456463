.flexCenterCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexStartCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexColumnCenterCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fullWidth {
  width: 100%;
}

.dividerMargin {
  margin-top: 40px !important;
  margin-bottom: 20px !important;
}