.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 16px;
  color: #7e0c00;
  background-color: #fcbeb8;
  width: 100%;
  border-radius: 2px;
  margin-top: 33px;
}
