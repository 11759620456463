.logo {
    width: 45px !important;
    height: 45px !important;
}

.name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
}