.container.close>div.sidebar {
  width: 90px;
}

.container.close>div.viewboard {
  margin-left: 90px;
}

.container>div.sidebar {
  position: fixed;
  width: 300px;
  height: 100vh;
  background-color: var(--sidebar-bg);
  box-shadow: var(--box-shadow-1);
}

.container>div.viewboard {
  padding: 15px 32px;
  margin-left: 300px;
  background-color: var(--dashboard-bg);
}

.dashboard {
  margin-top: 30px;
  background-color: white;
  min-height: 90vh;
  padding: 20px 0;
  border-radius: 4px;
}

.appbar {
  display: none;
  width: 100%;
  height: 70px;
  background-color: var(--sidebar-bg);
  z-index: 1000;
  align-items: center;
  padding: 0 32px;
}

@media only screen and (max-width: 1023px) {
  .sidebar {
    display: none;
  }

  .appbar {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  .container>.viewboard {
    margin-top: 70px !important;
    margin-left: 0 !important;
  }

  .container.close>div.viewboard {
    margin-top: 70px !important;
    margin-left: 0 !important;
  }

}