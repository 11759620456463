.textLogo {
    font-family: 'Reem Kufi';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 0;
    color: white;
    margin-left: 10px;
}

.logo {
    width: 30px !important;
    height: 30px !important;
}

.list {
    padding: 20px;
    background-color: var(--sidebar-bg);
}