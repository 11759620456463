.chartHeight {
  width: 1500px;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}