.footer {
    text-align: center;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
}

@media only screen and (max-width: 1024px) {
    .footer,
    .form {
        padding-bottom: 20px;
    }
}