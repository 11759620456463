.divider>div {
  height: 35px;
  width: 1px;
  border-left: solid 1px var(--color-gray);
  border-right: solid 1px var(--color-gray);
  margin-top: 25px;
}

.buttonText {
  height: 48px;
}

.forwardIcon>* {
  margin-top: 30px;
  color: var(--color-gray);
}


@media only screen and (max-width: 1200px) {

  .divider {
    display: none !important;
  }

  .forwardIcon {
    display: none !important;
  }
}