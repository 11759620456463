.textLogo {
    font-family: 'Reem Kufi';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    color: white;
    margin-left: 10px;
}

.container {
    padding: 0 15px;
}

.logo {
    width: 40px !important;
    height: 40px !important;
}