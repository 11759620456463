.divider>div {
  height: 35px;
  width: 1px;
  border-left: solid 1px var(--color-gray);
  border-right: solid 1px var(--color-gray);
  margin-top: 25px;
}

.buttonText {
  margin-top: 16px !important;
  margin-bottom: 8px !important;
  height: 56px;
}


@media only screen and (max-width: 1200px) {

  .buttonText {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 48px;
  }
}