.textLogo {
    font-family: 'Reem Kufi';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 18px;
    margin-bottom: 18px;
    margin-top: 0;
}

.logo {
    width: 60px;
    height: 60px;
}
